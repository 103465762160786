import React from "react";
import {graphql} from 'gatsby';
import FilterPage from "../components/FilterPage";
import Seo from "../components/Seo";


const CaseStudies = (props) => {
  return (
    <FilterPage
      title={'Case Studies'}
      description={'Hear from universities and colleges of all sizes how Clean Catalog has helped them manage their catalogs, curriculum, and syllabi.'}
      data={props.data}
      location={props.location}
      hideSort={true}
    />
  )
}

export default CaseStudies;

export function Head(props) {
  return (
    <Seo
      title={'Case Studies'}
      description={'Hear from universities and colleges of all sizes how Clean Catalog has helped them manage their catalogs, curriculum, and syllabi.'}
      canonical={props.location.pathname}
    />
  )
}


export const pageQuery = graphql`
query
{
  allMarkdownRemark(filter
:
  {
    frontmatter: {
      type: {
        eq: "casestudy"
      }
    }
  }
)
  {
    edges
    {
      node
      {
        fields
        {
          slug
        }
        frontmatter
        {
          date
          title
          tags
          path
          logo
          featured
          order
        }
        excerpt
      }
    }
    group(field
  :
    frontmatter___tags
  )
    {
      fieldValue
    }
  }
}
`

